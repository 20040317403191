import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  Avatar,
  Button,
  Typography,
} from 'antd';
import Linkify from 'react-linkify';
import { BottomSheet } from 'react-spring-bottom-sheet';

import DeleteSvg from '../../../../assets/svg/Delete';
import ReplySvg from '../../../../assets/svg/ReplySvg';
import BottomSheetTile
  from '../../../../components/BottomSheetTile/BottomSheetTile';
import MenuButton from '../../../../components/MenuButton/MenuButton';
import ConfirmationModal
  from '../../../../components/Modals/ConfirmationModal/ConfirmationModal';
import Report from '../../../../components/Report/Report';
import { useTheme } from '../../../../context/ThemeProvider';
import { checkForPermissions } from '../../../../context/userReducer';
import {
  useAppNavigate,
  useAppSelector,
} from '../../../../shared/hooks';
import {
  nFormat,
  timeAgo,
} from '../../../../shared/utils';
import { ROUTES } from '../../../../types/routes';
import { UAMPermissions } from '../../../../types/userTypes';

const regex = /{{[0-9a-f]{24}#[\s\S]*?}}/g;

interface Props {
  creator: string;
  profilePic: string;
  name: string;
  replyCount?: number;
  content: string;
  onLikeClick?: () => void;
  onReplyClick?: () => void;
  onRepliesCountClick?: () => void;
  liked: boolean;
  likeCount: number;
  createdAt: string;
  noOfLines?: number;
  selected: boolean;
  userId: string;
  onRemoveComment?: (commentId: string) => void;
  commentId: string;
  hideResiduary?: boolean;
  type?: 'comment' | 'reply';
}

const PostCommentCard: React.FC<Props> = ({
  creator,
  name,
  profilePic,
  replyCount,
  onLikeClick,
  onReplyClick,
  onRepliesCountClick,
  likeCount,
  liked,
  createdAt,
  content,
  noOfLines = 3,
  hideResiduary = false,
  userId,
  onRemoveComment,
  commentId,
  type = 'comment',
  ...props
}) => {
  const navigate = useAppNavigate();

  const { colors } = useTheme();

  const userDetails = useAppSelector((state) => state.user);
  const { isTagMango } = useAppSelector((state) => state.app);

  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState<
    'report' | 'delete' | null
  >(null);
  const [showReportModal, setShowReportModal] = useState(false);

  const hasFeedWriteAccess =
    userDetails?.type === 'creator_completed' ||
    (userDetails?.type === 'creator_restricted' &&
      checkForPermissions(
        [UAMPermissions.FEED_WRITE],
        userDetails.userAccessPermissions,
      ));

  const goToUserProfile = useCallback(
    (userProfileId: string) => {
      if (userProfileId) {
        navigate(ROUTES.USER_PROFILE, {
          userId: userProfileId,
        });
      }
    },
    [navigate],
  );

  // const options: HTMLReactParserOptions = {
  //   replace: (domNode: DOMNode) => {
  //     if (domNode instanceof Element && domNode.type === 'tag') {
  //       if (
  //         !(
  //           domNode.name === 'span' &&
  //           domNode.attribs &&
  //           domNode.attribs.style === 'color: #3480eb'
  //         )
  //       ) {
  //         const newNode = { ...domNode, name: 'span' };
  //         return domToReact([newNode], options);
  //       }
  //     }
  //   },
  // };

  // const modifyCommentString = (selectedText = '') => {
  //   const regex = /{{([0-9a-f]{24})#([^}]+)}}/gm;
  //   let finalText = selectedText;
  //   const textCopy = `${selectedText}`.matchAll(regex);
  //   Array.from(textCopy)?.forEach((element) => {
  //     finalText = finalText.replaceAll(
  //       element[0],
  //       `<span style="color: #3480eb"> @${element[0]
  //         .split('#')[1]
  //         .slice(0, -2)} </span>`,
  //     );
  //   });
  //   return HtmlParser(finalText, options);
  // };

  const getNameFromMentiontag = (tag: string) =>
    tag.substring(tag.indexOf('#') + 1, tag.length - 2);

  const getIdFromMentiontag = (tag: string) =>
    tag.substring(2, tag.indexOf('#'));

  const textToRender = useMemo(() => {
    const text = content;
    const tagsRegexResult = `${text}`.match(regex);
    const tags: string[] = [];

    tagsRegexResult?.forEach((element) => {
      tags.push(element);
    });

    const comment = text as string;
    const splits = comment.split(regex);

    const nodes: React.ReactNode[] = [];
    let newText = '';

    splits.forEach((textPart, index) => {
      nodes.push(textPart);
      newText += textPart;

      if (index < tags.length) {
        const tag = tags[index];
        const tagName = getNameFromMentiontag(tag);
        const mentionedUserId = getIdFromMentiontag(tag);

        newText += `@${tagName}`;

        nodes.push(
          <span
            key={mentionedUserId}
            role="button"
            tabIndex={0}
            onClick={() => goToUserProfile(mentionedUserId)}
            style={{ color: colors.INFO_ALT, cursor: 'pointer' }}>
            @{tagName}
          </span>,
        );
      }
    });

    return { nodes, text: newText };
  }, [colors.INFO_ALT, content, goToUserProfile]);

  return (
    <>
      <span>
        <Avatar
          src={profilePic}
          alt="Creator Name"
          size={32}
          onClick={() => goToUserProfile(userId)}
          style={{ cursor: 'pointer' }}
        />

        <div
          className={
            props.selected
              ? 'postCommentContainer selected'
              : 'postCommentContainer'
          }>
          <Typography.Text
            ellipsis={false}
            className="commentAuthor"
            onClick={() => goToUserProfile(userId)}
            style={{ cursor: 'pointer' }}>
            {name}
          </Typography.Text>
          <Linkify>
            <Typography.Paragraph
              ellipsis={{
                rows: noOfLines,
                symbol: 'See more',
                expandable: true,
              }}
              className="postCommentText"
              id={commentId}>
              {/* {modifyCommentString(content)} */}
              {textToRender.nodes}
            </Typography.Paragraph>
          </Linkify>
          {likeCount > 0 ? (
            <span className="postLikeCount">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.575"
                height="10.756"
                viewBox="0 0 11.575 10.756">
                <g id="heart" transform="translate(0 -16.896)">
                  <g
                    id="Group_4353"
                    data-name="Group 4353"
                    transform="translate(0 16.896)">
                    <path
                      id="Path_807"
                      data-name="Path 807"
                      d="M10.628,17.907a3.163,3.163,0,0,0-4.636,0l-.2.216-.2-.216a3.165,3.165,0,0,0-4.473-.164q-.085.079-.164.164a3.55,3.55,0,0,0,0,4.827l4.541,4.789a.414.414,0,0,0,.585.016l.016-.016,4.54-4.789A3.549,3.549,0,0,0,10.628,17.907Z"
                      transform="translate(0 -16.896)"
                      fill={liked ? colors.DANGER : colors.SECONDARY}
                    />
                  </g>
                </g>
              </svg>
              {nFormat(likeCount)}
            </span>
          ) : null}
        </div>

        {!hideResiduary ? (
          <MenuButton
            onClick={() => {
              setShowBottomSheet(true);
            }}
          />
        ) : null}
      </span>

      <span className="postCommentAction">
        {!hideResiduary ? (
          <>
            <Button
              className={`siteBtn siteBtnLink dark ${
                liked ? 'active' : ''
              } like`}
              onClick={onLikeClick}>
              {liked ? 'Liked' : 'Like'}
            </Button>
            <div className="circularSeperator">•</div>
            <Button className="siteBtn siteBtnLink dark" onClick={onReplyClick}>
              Reply
            </Button>
            <div className="circularSeperator">•</div>
          </>
        ) : null}
        <span className="postCommentTime dark">{timeAgo(createdAt)}</span>
      </span>

      {replyCount && (
        <span className="postCommentReplyCount" style={{ cursor: 'pointer' }}>
          <ReplySvg />
          <span
            className="replyCount dark"
            role="button"
            onClick={onRepliesCountClick}>
            {`View ${nFormat(replyCount)} ${replyCount > 1 ? 'replies' : 'reply'}`}
          </span>
        </span>
      )}

      <BottomSheet
        open={showBottomSheet}
        onDismiss={() => setShowBottomSheet(false)}>
        {userId === userDetails.id || (!isTagMango && hasFeedWriteAccess) ? (
          <BottomSheetTile
            key={1}
            icon={<DeleteSvg width={20} height={20} />}
            warning
            alignIcon="flex-start"
            title={`Remove ${type === 'comment' ? 'comment' : 'reply'}`}
            description={
              type === 'comment'
                ? 'The comment and all of its replies will be removed from this thread'
                : 'The reply will be removed from this thread'
            }
            onClick={() => {
              setIsDialogVisible('delete');
              setShowBottomSheet(false);
            }}
          />
        ) : (
          <BottomSheetTile
            key={2}
            icon={<DeleteSvg width={20} height={20} />}
            warning
            alignIcon="flex-start"
            title={`Report ${type === 'comment' ? 'comment' : 'reply'}`}
            description={
              type === 'comment'
                ? 'The comment will be reported'
                : 'The reply will be reported'
            }
            onClick={() => {
              setIsDialogVisible('report');
              setShowBottomSheet(false);
            }}
          />
        )}
      </BottomSheet>

      <ConfirmationModal
        open={Boolean(isDialogVisible)}
        message={`Do you really want to ${isDialogVisible} the ${
          type === 'comment' ? 'comment' : 'reply'
        }?`}
        title={isDialogVisible === 'report' ? 'Report' : 'Remove'}
        okayButtonText={isDialogVisible === 'report' ? 'Report' : 'Delete'}
        cancelButtonText="Cancel"
        handleOk={() => {
          if (isDialogVisible === 'delete' && onRemoveComment)
            onRemoveComment(commentId);
          else if (isDialogVisible === 'report') {
            setShowReportModal(true);
          }
          setIsDialogVisible(null);
        }}
        handleCancel={() => {
          setIsDialogVisible(null);
        }}
      />

      <Report
        showModal={showReportModal}
        closeModal={() => setShowReportModal(false)}
        type="comment"
        comment={{
          commentId,
          content,
          createdAt,
          likeCount,
          replyCount,
          userId,
          name,
          profilePic,
          creator,
          liked,
        }}
      />
    </>
  );
};

export default React.memo(PostCommentCard);
