import './styles.scss';

import React, { useMemo, useState } from 'react';

import { Space, Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualized from 'easy-react-virtualized';
import moment from 'moment';
import { BottomSheet } from 'react-spring-bottom-sheet';

import GamificationAPI from '../../../../api/GamificationAPI';
import Header from '../../../../components/Header/Header';
import Loader from '../../../../components/Loader';
import { useTheme } from '../../../../context/ThemeProvider';
import { setUserDetails } from '../../../../context/userReducer';
import { useAppSelector } from '../../../../shared/hooks';
import { nFormat } from '../../../../shared/utils';
import { IPointsLedger } from './types';

interface Props {
  handleBack?: () => void;
}

interface IState {
  refreshing: boolean;
  loading: boolean;
  ledger: IPointsLedger[];
  page: number;
  hasMore: boolean;
}

const initialState: IState = {
  refreshing: false,
  loading: false,
  ledger: [],
  page: 1,
  hasMore: true,
};

interface IListItem {
  type: 'header' | 'item';
  title: string;
  data?: IPointsLedger;
  isCreatorRemark?: boolean;
}

const PAGE_LIMIT = 50;

const PointsLedger: React.FC<Props> = ({ handleBack }) => {
  const { colors } = useTheme();

  const {
    hostMetadata: { pointsConfig, creator },
  } = useAppSelector((state) => state.app);
  const userDetails = useAppSelector((state) => state.user);

  const [selectedRemark, setSelectedRemark] = useState<string | null>(null);
  const [state, setState] = useState<IState>(initialState);

  const showRemark = (remark: string) => {
    setSelectedRemark(remark);
  };

  const hideRemark = () => {
    setSelectedRemark(null);
  };

  const fetchData = async (
    pageCount: number,
    ledger = state.ledger,
    hasMore = state.hasMore,
    loading = state.loading,
    currState = state,
  ) => {
    if (hasMore && !loading) {
      setState({ ...currState, loading: true });
      try {
        const response = await GamificationAPI.getPointsLedger(
          pageCount,
          PAGE_LIMIT,
        );
        let newState = { ...currState, loading: false, refreshing: false };
        if (response.status === 200) {
          const res = response.data.result;
          if (res.totalPoints?.score) {
            setUserDetails({
              score: {
                week: 0,
                month: 0,
                ...userDetails.score,
                lifetime: res.totalPoints.score,
              },
            });
          }
          if (res && res.ledgerList && res.ledgerList.length) {
            const list: IPointsLedger[] = res.ledgerList;
            const updatedLedger =
              pageCount > 1 ? [...(ledger || []), ...list] : [...list];
            newState = {
              ...newState,
              ledger: updatedLedger,
              page: pageCount + 1,
              hasMore: list.length === PAGE_LIMIT,
            };
          } else {
            newState = {
              ...newState,
              ledger: [...(ledger || [])],
              hasMore: false,
            };
          }
        }
        setState({ ...state, ...newState });
      } catch (error) {
        console.log(error);
      }
    }
  };

  // const loadData = () => {
  //   const newState: IState = {
  //     ledger: [],
  //     hasMore: true,
  //     page: 1,
  //     loading: false,
  //     refreshing: false,
  //   };
  //   setState((prev) => ({
  //     ...prev,
  //     ledger: [],
  //     hasMore: true,
  //     page: 1,
  //   }));
  //   fetchData(1, [], true, false, newState);
  // };

  const loadMore = async () => {
    if (state.hasMore && !state.loading)
      await fetchData(state.page, state.ledger);
  };

  // useEffect(() => {
  //   loadData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const formattedScoreItem = (score: number) => {
    const sign = score < 0 ? '-' : '+';
    const multiplier = score < 0 ? -1 : 1;

    if (!score) {
      return <Typography.Text>0 {pointsConfig?.pointsName}</Typography.Text>;
    }

    return (
      <Typography.Text
        style={{
          color: score < 0 ? colors.DANGER : colors.GREEN,
          fontWeight: 500,
        }}>
        {sign}
        {nFormat(score * multiplier, 999)} {pointsConfig?.pointsName}
      </Typography.Text>
    );
  };

  const groupByDate = (ledger: IPointsLedger[]) => {
    const grouped: { [key: string]: IPointsLedger[] } = {};
    ledger.forEach((item) => {
      const key = moment(item.date).format('DD-MM-YYYY');
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(item);
    });
    return grouped;
  };

  const groupedLedger = useMemo(() => {
    const grouped = groupByDate(state.ledger);

    // sort by date
    const sortedGrouped: { [key: string]: IPointsLedger[] } = {};
    Object.keys(grouped)
      .sort((a, b) => {
        return moment(b, 'DD-MM-YYYY').diff(moment(a, 'DD-MM-YYYY'));
      })
      .forEach((key) => {
        sortedGrouped[key] = grouped[key];
      });

    // return array of objects to be rendered
    const result: IListItem[] = [];
    Object.entries(sortedGrouped).forEach(([key, value]) => {
      result.push({
        type: 'header',
        title: moment(key, 'DD-MM-YYYY').isSame(moment(), 'day')
          ? 'Today'
          : moment(key, 'DD-MM-YYYY').format('DD MMM YYYY'),
      });
      value.forEach((item) => {
        result.push({
          type: 'item',
          title:
            item.type === 'custom'
              ? creator?.name || "Creator's Remark"
              : item.description || item.type,
          data: item,
          isCreatorRemark: item.type === 'custom',
        });
      });
    });
    return result;
  }, [creator?.name, state.ledger]);

  return (
    <>
      <div className="postdetails__container">
        <Header title="Points Earned" handleBack={handleBack} />

        <div className="scroll__container points__container">
          <div className="my-points-card">
            <img
              src={pointsConfig?.pointsImage}
              alt={`${pointsConfig?.pointsName} Icon`}
            />
            <div className="my-points-card__details">
              <div className="my-points-card__details__title">My Points</div>
              <div className="my-points-card__details__points">
                {nFormat(userDetails.score?.lifetime || 0, 9999, 2)}{' '}
                <span>{pointsConfig?.pointsName}</span>
              </div>
            </div>
          </div>

          {/* <div className="points-details"> */}
          <EasyVirtualized
            onLoadMore={loadMore}
            useParentScrollElement
            hasMore={state.hasMore}>
            {groupedLedger.map((item) => {
              if (item.type === 'header') {
                return (
                  <div className="points-details__item" key={item.title}>
                    <div className="points-details__item__title">
                      <span>{item.title}</span>
                    </div>
                  </div>
                );
              }
              if (!item.data) return null;
              const point = item.data;
              return (
                <div className="points-details__item" key={point?._id}>
                  <div className="list-item">
                    <div className="list-item__details">
                      <div className="list-item__details__title">
                        <Space align="center" size={0}>
                          {item.title}
                          {item.isCreatorRemark && (
                            <Typography.Text className="creator-tag">
                              CREATOR
                            </Typography.Text>
                          )}
                        </Space>
                      </div>
                      <div className="list-item__details__points">
                        {formattedScoreItem(point.score)}
                      </div>
                    </div>
                    {item.isCreatorRemark && point.description ? (
                      <div
                        className="list-item__details__remark-btn"
                        onClick={() => {
                          if (point.description) showRemark(point.description);
                        }}>
                        Remark
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </EasyVirtualized>
          {/* </div> */}
          {state.loading || state.hasMore ? (
            <Loader
              style={{
                margin: '16px 0',
                width: '100%',
              }}
            />
          ) : null}
        </div>
      </div>

      {/* Remark by creator in points */}
      <BottomSheet
        open={Boolean(selectedRemark)}
        onDismiss={hideRemark}
        header={<Header canGoBack={false} title="Remark" noBorder />}>
        {selectedRemark ? (
          <div className="points__remark">{selectedRemark}</div>
        ) : null}
      </BottomSheet>
    </>
  );
};

export default PointsLedger;
